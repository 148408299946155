@import "../../theme.scss";

.user-event-log {
  border: $row-border;
}

.user-log-event-header {
  font-weight: bold;
  padding: 1mm 1em;
  overflow: hidden;
  white-space: nowrap;
  background-color: $data-table-header;
}

.user-log-event-row {
  border-bottom: $row-border;
  padding: 1mm 1em;
  overflow: hidden;
}

.user-log-event-row:hover {
  background-color: $click-me;
}

.user-log-event-item {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  vertical-align: middle;
  font-size: small;
}

.user-log-event-index {
  @extend .user-log-event-item;
  display: $table-index-display;
  color: gray;
  font-size: smaller;
  text-align: right;
  margin-right: 2em;
  width: 3em;
}

.user-log-event-timestamp {
  @extend .user-log-event-item;
  display: inline-block;
  width: 15em;
}

.user-log-event-type {
  @extend .user-log-event-item;
  display: inline-block;
  width: 15em;
}

.user-log-event-username {
  @extend .user-log-event-item;
  display: inline-block;
  width: 25em;
}

.user-log-event-host {
  @extend .user-log-event-item;
  display: inline-block;
  width: 10em;
}

.user-log-event-application {
  @extend .user-log-event-item;
  display: inline-block;
  width: 10em;
}
