@import "../../theme.scss";

.admins {
  border: $row-border;
}

.admin-row:hover {
  background-color: $click-me;
}

.admin-header {
  font-weight: bold;
  padding: 1mm 1em;
  white-space: nowrap;
  overflow: hidden;
  background-color: $data-table-header;
}

.admin-row {
  border-bottom: $row-border;
  padding: 1mm 1em;
  overflow: hidden;
}

.admin-item {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  vertical-align: middle;
  font-size: small;
}

.admin-index {
  @extend .admin-item;
  display: $table-index-display;
  color: gray;
  font-size: smaller;
  text-align: right;
  margin-right: 2em;
  width: 3em;
}

.admin-username {
  @extend .admin-item;
  display: inline-block;
  width: 15em;
}

.admin-fullname {
  @extend .admin-item;
  display: inline-block;
  width: 15em;
}

.admin-email {
  @extend .admin-item;
  display: inline-block;
  width: 15em;
}

.admin-status {
  @extend .admin-item;
  display: inline-block;
  width: 10em;
}

.admin-lastseen {
  @extend .admin-item;
  display: inline-block;
  width: 10em;
}
