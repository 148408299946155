@import "../../theme.scss";

.login-page-card {
  grid-area: login;
}

.login-validation-error {
  height: 1.5em;
  color: red;
}

.status-bar {
  grid-area: status;
  border-top: $page-border;
}

.fillwidth {
  width: 100%;
}
