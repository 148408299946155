@import "../../theme.scss";

.user-manager {
  border: $row-border;
}

.user-manager-header {
  font-weight: bold;
  padding: 1mm 1em;
  white-space: nowrap;
  overflow: hidden;
  background-color: $data-table-header;
}

.user-manager-row {
  border-bottom: $row-border;
  padding: 1mm 1em;
  cursor: pointer;
  overflow: hidden;
}

.user-manager-row-archived {
  border-bottom: $row-border;
  padding: 1mm 1em;
  cursor: default;
  overflow: hidden;
  color: gray;
}

.user-manager-row:hover {
  background-color: $click-me;
}

.user-manager-item {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  vertical-align: middle;
  font-size: small;
  margin-right: 1em;
}

.user-manager-index {
  @extend .user-manager-item;
  display: $table-index-display;
  text-align: right;
  color: gray;
  font-size: smaller;
  width: 3em;
}

.user-manager-username {
  @extend .user-manager-item;
  display: inline-block;
  width: 15em;
}

.user-manager-fullname {
  @extend .user-manager-item;
  display: inline-block;
  width: 15em;
}

.user-manager-email {
  @extend .user-manager-item;
  display: inline-block;
  width: 15em;
}

.user-manager-status {
  @extend .user-manager-item;
  display: inline-block;
  width: 10em;
}

.user-manager-lastseen {
  @extend .user-manager-item;
  display: inline-block;
  width: 10em;
}

.user-search-bar {
  display: flex;
  font-weight: bold;

  .bp4-form-group {
    margin-right: 5mm;
  }

  label {
    user-select: none;
  }

  .user-count {
    padding-top: 0.5em;
    color: $data-table-header;
    user-select: none;
  }
}
