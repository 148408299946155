@import "../../theme.scss";

.banner {
  grid-area: banner;
}

.title {
  grid-area: title;
  padding: 0 4mm;
  border-bottom: $page-border;
}

.sidebar {
  grid-area: sidebar;
  margin: 0;
  padding: 4mm;
  border-right: $page-border;
  overflow: auto;
}

.main {
  grid-area: main;
  margin: 0;
  padding: 4mm;
  overflow: auto;
}

.sidebar-menu {
  border: $menu-border;
  background-color: $menu-background;
  margin-bottom: 4mm;
  font-size: 10pt;
}

.status {
  grid-area: status;
  border-top: $page-border;
}
