@import "../theme.scss";

.status-bar {
  display: flex;
  justify-content: space-between;
  background-color: #f0f0f0;
  padding: 0.75em 1em;
  height: 100%;
  font-size: small;
}
