@import "../theme.scss";

.password-change-dialog {
  min-width: 42em;
}

.password-change-field {
  height: 2em;
  padding: 1mm 1.5em 0 1.5em;

  label {
    width: 12em;
    text-align: right;
    white-space: nowrap;
    font-weight: bold;
  }

  input {
    font-size: small;
  }
}

.validation-error {
  display: inline;
  color: red;
  font-size: small;
  font-weight: bold;
}

.change-password-error {
  color: red;
  text-align: center;
}
