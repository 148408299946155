@import "../../theme.scss";

.tenants-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.microsearch-item {
  cursor: pointer;
  border: $tenant-item-border;
  color: $palette-4;
  background-color: maroon;
  border-radius: 1mm;
  margin: 2mm;
  padding: 0 4mm;
}

.microsearch-item:hover {
  background-color: rosybrown;
  box-shadow: 0 0 2mm 1mm rosybrown;
}

.tenant-item {
  cursor: pointer;
  border: $tenant-item-border;
  background-color: $tenant-item-background-color;
  border-radius: 1mm;
  margin: 2mm;
  padding: 0 4mm;
}

.tenant-item:hover {
  background-color: $click-me;
  box-shadow: 0 0 2mm 1mm $click-me;
}

.tenant-name {
  font-size: 14pt;
  font-weight: bold;
  padding: 1mm 0 1mm 0;
}

.tenant-description {
  font-size: 9pt;
  padding-bottom: 1mm;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.banner {
  grid-area: banner;
}

.controls {
  margin: 0;
  padding: 4mm;
  grid-area: main;
}

.status {
  grid-area: status;
  border-top: $page-border;
}

#create-tenant-form label {
  width: 8em;
  text-align: right;
}

#create-tenant-form button {
  margin-left: 8.75em;
}

.error-display {
  height: 1.5em;
  margin-left: 8.75em;
  margin-bottom: 1mm;
  color: red;
}
