.password-strength-meter {
  display: flex;
  // justify-content: space-between;
}

.password-warning {
  font-size: small;
  font-weight: bold;
  color: red;
}

.suggestions-popover {
  padding: 2mm 5mm;
  background-color: lightgoldenrodyellow;
}
