.login-page {
  display: grid;
  grid-template-areas:
    "banner banner banner"
    ". . ."
    ". login ."
    ". . ."
    "status status status";
  grid-template-rows: 1.5cm 2cm auto auto 1cm;
  grid-template-columns: auto minmax(20em, 33vw) auto;
  height: 100vh;
  width: 100vw;
}

.home-page {
  display: grid;
  grid-template-areas:
    "banner  banner"
    "sidebar main"
    "status  status";
  grid-template-rows: 1.5cm auto 1cm;
  grid-template-columns: 10cm auto;
  height: 100vh;
  width: 100vw;
}

.admins-page {
  display: grid;
  grid-template-areas:
    "banner  banner"
    "title title"
    "sidebar main"
    "status  status";
  grid-template-rows: 1.5cm 2cm auto 1cm;
  grid-template-columns: 6cm auto;
  height: 100vh;
  width: 100vw;
}

.tenant-page {
  display: grid;
  grid-template-areas:
    "banner  banner"
    "tenant  tenant"
    "sidebar main"
    "status  status";
  grid-template-rows: 1.5cm 2cm auto 1cm;
  grid-template-columns: 6cm auto;
  height: 100vh;
  width: 100vw;
}

$palette-1: #204051;
$palette-2: #3b6978;
$palette-3: #84a9ac;
$palette-4: #cae8d5;

$click-me: $palette-4;

$page-border: 1px dashed lightgray;
$row-border: 1px dotted lightgray;

$menu-background: #fafafa;
$menu-border: 1px solid gray;

$data-table-header: $palette-3;
$data-table-header-color: $palette-1;

$data-table-footer: #fafafa;
$data-table-footer-color: $palette-2;

$tenant-item-border: 2px solid $palette-1;
$tenant-item-background-color: $palette-3;

$table-hilite: $palette-4;
$table-border-bottom: 1px dotted lightgray;

// change to inline-block to debug virtual tables
$table-index-display: none;

/*
  blueprintjs theme overrides
*/
$dark-navbar-background-color: $palette-1;
$menu-item-color-hover: $click-me;
$menu-item-color-active: $click-me;

@function svg-icon($inline-svg, $fill-color) {
  @return none;
}

@import "~@blueprintjs/core/src/blueprint.scss";
