@import "../../theme.scss";

.data-table {
  border: 1px solid $data-table-header;
}

.data-table thead {
  background-color: $data-table-header;
  font-weight: bold;
}

.data-table tbody tr td {
  color: $data-table-header-color;
}

.data-table tfoot {
  background-color: $data-table-footer;
  font-weight: bold;
}

.data-table tfoot tr td {
  border-top: $row-border;
  color: $data-table-footer-color;
}

.data-table-numeric {
  text-align: right !important;
}
