@import "../theme.scss";

.roles-data-table {
  border: 1px solid $data-table-header;

  thead {
    background-color: $data-table-header;
    font-weight: bold;
  }

  tbody :hover {
    background-color: $click-me;
    cursor: pointer;
  }
}
